import p5 from "p5";
import P5Wrapper from "react-p5-wrapper";

const s = (p5) => {
  let population;
  var lifespan = 160;
  var lifeP;
  var maxFitP;
  var minFitP;
  var count = 0;
  var target;
  var popSize = 100;

  var gWidth = 800;
  var gHeight = 800;

  p5.setup = () => {
    const canvas = p5.createCanvas(gWidth, gHeight);
    canvas.parent("SmartRocketTileIDBecauseP5JSIsJank");

    population = new Population(popSize);
    target = { x: gWidth / 2, y: gHeight / 6 };

    p5.background(40);
  };

  p5.draw = () => {
    p5.background("rgba(0,0,0,0.005)");
    population.run();

    count++;
    if (count == lifespan) {
      population.setMatingPool();
      population.setRockets();
      count = 0;
    }

    p5.fill("rgb(249, 38, 114)");
    p5.ellipse(target.x, target.y, 16, 16);
  };

  function Population(popSize) {
    this.popSize = popSize;
    this.rockets = [];

    // Set initial rockets.
    for (let i = 0; i < this.popSize; i++) {
      this.rockets[i] = new Rocket();
    }

    this.matingPool = [];

    // The process of natural selection!
    // Sets MatingPool.
    this.setMatingPool = function () {
      let maxfit = 0;

      // Sets each rockets fitness.
      this.rockets.forEach((rocket) => {
        rocket.calcFitness();

        if (rocket.fitness > maxfit) {
          maxfit = rocket.fitness;
        }
      });

      this.rockets.forEach((rocket) => {
        rocket.fitness /= maxfit;
      });

      // Sets mating pool
      this.matingPool = [];

      this.rockets.forEach((rocket) => {
        const n = rocket.fitness * 1000;

        for (let j = 0; j < n; j++) {
          this.matingPool.push(rocket);
        }
      });
    };

    // Sets rockets. Uses matingPool.
    this.setRockets = function () {
      let newRockets = [];

      for (var i = 0; i < this.popSize; i++) {
        const a = Math.floor(Math.random() * this.matingPool.length);
        const b = Math.floor(Math.random() * this.matingPool.length);

        const parentA = this.matingPool[a];
        const parentB = this.matingPool[b];

        const child = parentA.dna.crossover(parentB);
        child.mutate();

        newRockets[i] = new Rocket(child);
      }

      this.rockets = newRockets;
    };

    this.run = function () {
      for (var i = 0; i < this.popSize; i++) {
        this.rockets[i].update();
        this.rockets[i].show();
      }
    };
  }

  function DNA(genes) {
    if (genes) {
      this.genes = genes;
    } else {
      this.genes = [];
      for (var i = 0; i < lifespan; i++) {
        this.genes[i] = [
          (Math.random() * 2 - 1) * 0.1,
          (Math.random() * 2 - 1) * 0.1,
        ];
      }
    }

    // For each gene spot, randomly chooses one from each partner.
    this.crossover = function (partner) {
      var newgenes = [];
      var mid = p5.floor(p5.random(this.genes.length));

      for (var i = 0; i < this.genes.length; i++) {
        if (i > mid) {
          newgenes[i] = this.genes[i];
        } else {
          newgenes[i] = partner.dna.genes[i];
        }
      }

      return new DNA(newgenes);
    };

    this.mutate = function () {
      for (var i = 0; i < this.genes.length; i++) {
        if (Math.random() < 0.01) {
          this.genes[i] = [
            (Math.random() * 2 - 1) * 0.1,
            (Math.random() * 2 - 1) * 0.1,
          ];
        }
      }
    };
  }

  function Rocket(dna) {
    this.pos = p5.createVector(gWidth / 2, (gHeight / 6) * 5); // width and height
    this.vel = p5.createVector();
    this.acc = p5.createVector();
    this.crashed = false;

    if (dna) {
      this.dna = dna;
    } else {
      this.dna = new DNA();
    }

    // this.fitness;

    this.applyForce = function (force) {
      // Acceleration is 0 after each update.
      this.acc.add(force);
    };

    this.calcFitness = function () {
      const d =
        1 /
        Math.sqrt((this.pos.x - target.x) ** 2 + (this.pos.y - target.y) ** 2);

      this.fitness = Math.pow(d, 10);
    };

    this.update = function () {
      this.applyForce(this.dna.genes[count]);

      this.vel.add(this.acc);
      this.pos.add(this.vel);
      this.acc.mult(0);
    };

    this.show = function () {
      p5.push(); // rotating and translating wont effect other objects
      p5.noStroke();
      p5.fill(255);
      p5.translate(this.pos.x, this.pos.y);

      p5.rotate(this.vel.heading()); // Gives angle vector is pointing
      p5.rectMode(p5.CENTER);
      p5.rect(0, 0, 5, 2);
      p5.pop();
    };
  }
};

const sketchInstance = new p5(s);

const SmartRockets = () => {
  return (
    <div
      className="Tile"
      style={{ width: 800 }}
      id="SmartRocketTileIDBecauseP5JSIsJank"
    >
      <P5Wrapper sketch={s} />
    </div>
  );
};

export default SmartRockets;
