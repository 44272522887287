import { useEffect, useRef } from "react";
import noise from "./perlin.js";

function Perlin(canvas) {
  let particles;

  // const canvas = document.getElementsByTagName("canvas")[0];

  const ctx = canvas.getContext("2d");

  const w = 800;
  const h = 600;

  canvas.width = w;
  canvas.height = h;

  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, w, h);

  noise.seed(Math.random());

  particles = [];

  // Add 2000 particles
  for (let _i = 1; _i <= 2000; _i++) {
    const p1 = {
      x: Math.random() * w,
      // y: h / 2 + Math.random() * 50,
      y: h / 2 + Math.random() * 50,
      angle: 0,
    };

    // Add a particle that will move in the opposite direction
    const p2 = {
      x: p1.x,
      y: p1.y - 1,
      angle: Math.PI,
    };

    particles.push(p1, p2);
  }

  function draw() {
    particles.forEach((p) => {
      // const period = 0.005;
      const scaleFactor = 0.002;

      // Get the 'height' from perlin noise.
      const noiseVal = noise.simplex2(p.x * scaleFactor, p.y * scaleFactor);

      const angle = 2 * Math.PI * noiseVal + p.angle;

      p.x += Math.cos(angle); // Add the X component of our angle
      p.y += Math.sin(angle); // Add the Y component of our angle

      ctx.fillStyle = `hsla(
        ${Math.floor(Math.abs(noiseVal * 400))}, 100%, 20%, 0.02)`;

      ctx.fillRect(p.x, p.y, 1, 1);
    });

    requestAnimationFrame(draw);
  }

  requestAnimationFrame(draw);
}

function Sketch() {
  const canvasEl = useRef(null);

  useEffect(() => {
    Perlin(canvasEl.current);
  }, []);

  function log(e) {
    const scaleFactor = 0.001;
    const eX = e.nativeEvent.offsetX;
    const eY = e.nativeEvent.offsetY;

    const v = noise.simplex2(eX * scaleFactor, eY * scaleFactor);
  }

  return (
    <div className="Tile">
      <canvas ref={canvasEl} onMouseMove={log} />
    </div>
  );
}

export default Sketch;
