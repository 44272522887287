import { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";

import Perlin from "./sketches/Perlin";
import SmartRockets from "./sketches/SmartRockets";

import SliderPane from "./SliderPane";

import Bolter from "./sketches/Bolter";
import Scope from "./sketches/Scope";

function App() {
  return (
    <div className="App">
      <header className="AppHeader">Don Pinkus</header>

      <div class="icons">
        <a
          href="https://www.linkedin.com/in/don-pinkus-9140702a/"
          target="_blank"
          class="icon"
        >
          <img src="/linkedin.svg" width="30%" />
        </a>
        <a
          href="https://twitter.com/WhatsDonIsDon"
          target="_blank"
          class="icon"
        >
          <img src="/twitter.svg" width="40%" />
        </a>
        <a href="https://dribbble.com/donpinkus" target="_blank" class="icon">
          <img src="/dribbble.svg" width="45%" />
        </a>
      </div>

      <main className="TileContainer">
        <SmartRockets />

        {/* <Perlin /> */}

        <div className="Tile">
          <div className="TileWave" />
          <video
            autoPlay
            muted
            playsInline
            loop={true}
            width="800px"
            src="https://cdn.dribbble.com/users/479513/screenshots/8277129/media/394a3b6064c531e70ae0a3db75ab06ff.mp4"
          />
        </div>

        <div className="Tile">
          <video
            autoPlay
            muted
            playsInline
            loop={true}
            width="800px"
            src="https://cdn.dribbble.com/users/479513/screenshots/8153422/media/4e694fa9fe0b7a01e23a5ce7f0c9cb34.mp4"
          />
        </div>

        <div className="Tile">
          <iframe
            height="800px"
            width="800px"
            style={{ width: "800px", height: 800 }}
            scrolling="no"
            title="Pot Maker - Three.js"
            src="https://codepen.io/donpinkus/embed/oogzKv?default-tab=result&theme-id=light"
            frameborder="no"
            loading="lazy"
            allowtransparency="true"
            allowfullscreen="true"
          >
            See the Pen{" "}
            <a href="https://codepen.io/donpinkus/pen/oogzKv">
              Pot Maker - Three.js
            </a>{" "}
            by Don Pinkus (<a href="https://codepen.io/donpinkus">@donpinkus</a>
            ) on <a href="https://codepen.io">CodePen</a>.
          </iframe>
        </div>

        <SliderPane
          leftImgURL="/bolter-black.jpg"
          leftImgPos="left center"
          rightImgURL="/bolter-red.jpg"
          rightImgPos="right 11px"
        />

        <SliderPane
          leftImgURL="/scope-clay.jpg"
          leftImgPos="26px 18px"
          rightImgURL="/scope-materials.jpg"
          rightImgPos="right 11px"
        />

        <div className="Tile">
          <iframe
            style={{ width: 800, height: 800 }}
            scrolling="no"
            title="Color Wheel"
            src="https://codepen.io/donpinkus/embed/BmzNLe?default-tab=result&theme-id=light"
            frameborder="no"
            loading="lazy"
            allowtransparency="true"
            allowfullscreen="true"
          >
            See the Pen{" "}
            <a href="https://codepen.io/donpinkus/pen/BmzNLe">Color Wheel</a> by
            Don Pinkus (<a href="https://codepen.io/donpinkus">@donpinkus</a>)
            on <a href="https://codepen.io">CodePen</a>.
          </iframe>
        </div>
      </main>
    </div>
  );
}

export default App;
