import { useState, useRef, useEffect } from "react";

const SliderPane = ({
  leftImgURL,
  rightImgURL,
  leftImgPos = "left center",
  rightImgPos = "right center",
}) => {
  const [sliderPos, setSliderPos] = useState(400);
  const [isFocused, setIsFocused] = useState(false);
  const isIncreasing = useRef(true);
  const rAF = useRef(null);
  const timer = useRef(null);

  const container = useRef(null);

  const updateSliderPos = function () {
    setSliderPos((prevPos) => {
      if (isIncreasing.current && prevPos >= 800) {
        isIncreasing.current = false;
      } else if (!isIncreasing.current && prevPos <= 0) {
        isIncreasing.current = true;
      }

      const currPos = prevPos;
      const newPos = prevPos + (isIncreasing.current ? 1 : -1);

      return newPos;
    });

    rAF.current = requestAnimationFrame(updateSliderPos);
  };

  useEffect(() => {
    if (isFocused) {
      cancelAnimationFrame(rAF.current);
    } else {
      cancelAnimationFrame(rAF.current);
      updateSliderPos();
    }
  }, [isFocused]);

  return (
    <div
      ref={container}
      className="Tile"
      onMouseMove={(ev) => {
        console.log("move");

        setIsFocused(true);

        setSliderPos(ev.pageX - container.current.offsetLeft);
        window.curr = container.current;

        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          setIsFocused(false);
        }, 3000);
      }}
      style={{
        width: 800,
        height: 600,
        position: "relative",
        overflow: "hidden",
        background: "#dadada",
      }}
    >
      <div
        style={{
          backgroundColor: "#cfcfcf",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("${leftImgURL}")`,
          backgroundPosition: leftImgPos,
          backgroundSize: "800px 600px",
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
      <div
        style={{
          backgroundRepeat: "no-repeat",
          backgroundImage: `url("${rightImgURL}")`,
          backgroundColor: "#dadada",
          backgroundPosition: rightImgPos,
          backgroundSize: "800px 600px",
          position: "absolute",
          top: 0,
          bottom: 0,
          left: `${sliderPos}px`,
          right: 0,
        }}
      />
      <div
        style={{
          border: "2px solid #6d5dfc",
          position: "absolute",
          borderRadius: "4px",
          top: "-20px",
          bottom: "-20px",
          left: `${sliderPos}px`,
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default SliderPane;
